import React, { useState } from "react";
import { db } from "../../firebase";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
// import emailjs from "@emailjs/browser"; // Import EmailJS
import emailjs from 'emailjs-com';


const UserDetails = ({ onClose, productDetails }) => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    address: "",
    pincode: "",
    state: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Load values from .env
  const ADMIN_EMAIL = process.env.REACT_APP_ADMIN_EMAIL2;
  const EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
  const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Phone number must be 10 digits";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email))
      newErrors.email = "Invalid email address";
    if (!formData.address.trim()) newErrors.address = "Address is required";
    if (!formData.pincode.trim()) newErrors.pincode = "Pincode is required";
    if (!formData.state.trim()) newErrors.state = "State is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendOrderEmails = (orderId) => {
    if (!formData.email || !formData.email.trim()) {
      console.error("Error: Customer email is empty.");
      return;
    }
    console.log("Customer Email Before Sending:", formData.email);
    console.log("Sending order email to:", formData.email);
    console.log("Sending admin email to:", ADMIN_EMAIL);
  
    // Email to Customer
    const customerEmailParams = {
      user_email: formData.email,  // Ensure this is set correctly
      customer_name: formData.name,
      product_name: productDetails.productTitle,
      quantity: productDetails.quantity,
      total_price: productDetails.totalPrice,
      order_id: orderId,
    };
  
    // Email to Admin
    const adminEmailParams = {
      user_email: ADMIN_EMAIL,  // Ensure this is properly set
      customer_name: formData.name,
      customer_email: formData.email,
      product_name: productDetails.productTitle,
      quantity: productDetails.quantity,
      total_price: productDetails.totalPrice,
      order_id: orderId,
    };
  
    // Send email to customer
    emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, customerEmailParams, EMAILJS_PUBLIC_KEY)
      .then((response) => console.log("Customer email sent successfully:", response))
      .catch((error) => console.error("Failed to send email to customer:", error));
  
    // Send email to admin
    emailjs.sendForm(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, adminEmailParams, EMAILJS_PUBLIC_KEY)
      .then((response) => console.log("Admin email sent successfully:", response))
      .catch((error) => console.error("Failed to send email to admin:", error));
  };

  
  const handleSubmit = async () => {
    if (!validate()) return;
    setLoading(true);

    try {
      const orderRef = await addDoc(collection(db, "Orders"), {
        ...formData,
        productTitle: productDetails.productTitle,
        quantity: productDetails.quantity,
        price: productDetails.price,
        totalPrice: productDetails.totalPrice,
        status: "Pending",
        createdAt: new Date(),
      });

      // Send order confirmation email to customer and admin
      sendOrderEmails(orderRef.id);

      // Proceed to Razorpay Payment
      handleRazorpayPayment(orderRef.id, productDetails.totalPrice);
    } catch (error) {
      console.error("Error saving order:", error);
      alert("Failed to save details. Please try again.");
      setLoading(false);
    }
  };

  const loadRazorpay = () => {
    return new Promise((resolve) => {
      if (document.getElementById("razorpay-script")) {
        resolve(true);
        return;
      }
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.id = "razorpay-script";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleRazorpayPayment = async (orderId, amount) => {
    const res = await loadRazorpay();

    if (!res) {
      alert("Failed to load Razorpay SDK.");
      setLoading(false);
      return;
    }

    const options = {
      key: RAZORPAY_KEY,
      amount: amount * 100,
      currency: "INR",
      name: "Your Company Name",
      description: `Payment for ${productDetails.productTitle}`,
      handler: async function (response) {
        const orderDocRef = doc(db, "Orders", orderId);
        await updateDoc(orderDocRef, {
          status: "Paid",
          paymentId: response.razorpay_payment_id,
        });

        alert("Payment Successful and order updated!");
        setLoading(false);
        onClose();
      },
      prefill: {
        name: formData.name,
        email: formData.email,
        contact: formData.phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    paymentObject.on("payment.failed", function (response) {
      alert("Payment Failed: " + response.error.description);
      setLoading(false);
    });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Enter Your Details</h2>
        <p className="text-gray-700">Product: {productDetails.productTitle}</p>
        <p className="text-gray-700">
          Quantity: {productDetails.quantity} x ₹{productDetails.price}
        </p>
        <p className="font-semibold text-lg">
          Total: ₹{productDetails.totalPrice}
        </p>

        <div className="space-y-4 mt-4">
          {Object.keys(formData).map((field) => (
            <div key={field}>
              <input
                type={field === "email" ? "email" : "text"}
                name={field}
                placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                value={formData[field]}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
              {errors[field] && (
                <p className="text-red-500 text-sm mt-1">{errors[field]}</p>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-end mt-4">
          <button className="px-4 py-2 bg-gray-200 rounded-lg mr-2 hover:bg-red-600 hover:text-white transition-colors duration-200" onClick={onClose} disabled={loading}>
            Cancel
          </button>
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={handleSubmit} disabled={loading}>
            {loading ? "Processing..." : "Proceed to Pay"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
