import ProductDetails from "./ProductDetails";

import product1_image1 from '../../assets/product1_image1.png';
import product1_image2 from '../../assets/product1_image2.png';
import product1_image3 from '../../assets/product1_image3.png';
import product1_image4 from '../../assets/product1_image4.png';
import product1_image5 from '../../assets/product1_image5.png';

const products = [
  {
    id: "product_1",
    title: "The 100 day Journal",
    description: `Introducing our very first product, and we couldn’t be prouder – the 100-Day Journal. \n🌸✨This isn’t just a journal. It’s a space to breathe, reflect, and connect with your true self every single day.\n 🌿💫 Each page brings you closer to inner peace, inviting you to explore a new topic, share your thoughts, and ease your mind. \n🖋️Why Journaling? 🌼 Unlock deeper self-awareness\n🌱 Cultivate gratitude, mindfulness, and clarity\n💭 Release stress, embrace creativity, and clear your thoughts\n❤️ Track growth, celebrate progress, and honor your journey\n🌙 Discover the magic of your own words\nEvery day is an opportunity for growth. So take a moment, breathe, and begin your journey with our 100-Day Journal. 🌟`,
    images: [product1_image1, product1_image2, product1_image3, product1_image4, product1_image5],
    originalPrice: 899,
    discountedPrice: 699,
    discount: 22
  }
]

const ProductList = () => {
  return (
    <div>
      {products.map((product) => (
          <ProductDetails key={product.id} product={product} />
      ))}
      </div>
    );
};
export default ProductList;